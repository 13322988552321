/*.header-wrapper, .header, .header--middle-left, .list-menu{
            display: none;
        }

div#shopify-section-sections--17190972620955__footer {
    display: none;
}


*/
.header-wrapper, .header, .header--middle-left, .list-menu {
    border: 0px;
}

        
.page-width--narrow {
    max-width: 100vw !important;        
    padding: 0px !important;
}

a.header__heading-link.link.link--text.focus-inset .h2 {
    color: rgb(18 18 18 / 75%) !important;
    line-height: 1 !important;
    font-size: calc(var(--font-heading-scale) * 2.4rem)  !important;
    font-weight: 400  !important;
    display: inline  !important;
    font-family: Assistant, sans-serif  !important;
}
    
.page-width--narrow {
    margin-top: -60px !important;
}

.eplus-styles-uid-f2a92b {
    color: white !important;
    font-size: 26px !important;
}


.hero-section{
    margin-top: 45px;
}

.header a, footer a{
  color: rgb(18 18 18 / 75%) !important;
  font-weight: 400 !important; 
}

.header-wrapper, .header, .header--middle-left, .list-menu{
    height: 44px !important;
    font-family: Assistant, sans-serif  !important;
}

.header__menu-item{
    font-size: 14px !important;
    font-family: Assistant, sans-serif  !important;
}

h4.wp-block-heading.has-text-align-center.eplus-wrapper.eplus-styles-uid-f2a92b {
    padding: 0px !important;
    margin: 0px  !important;
}

footer h2{
    font-size: calc(var(--font-heading-scale) * 1.8rem) !important;
    font-weight: 400 !important;
    font-body-family: Assistant, sans-serif !important;
}

.uat__image {
        display: flex  !important;
}

.section-template--16495976153308__16617174305670894b-padding .title-wrapper>*,
.uat__title p,
.iwr__content-info__heading-wrap p{
        font-size: 34px !important;
        font-weight: 600 !important;
        color: black !important;
    }  
    
a.button.button--primary:hover {
    color: white;
    border: none; 
}
    
    
@media screen and (max-width:749px) {
    .hero-section__media-item, .hero-section__media-block mobile-hide {
        display: none !important;
    }
    .hero-section__media-circle {
        padding-bottom: 80%;
        margin-left: 65%;
        width: 80%;
        background-color: #fff;
        border-radius: 50%;    
    }
.section-template--16495976153308__16617174305670894b-padding .title-wrapper>*,
.uat__title p,
.iwr__content-info__heading-wrap p{
        font-size: 25px !important;
        font-weight: 600 !important;
        color: black !important;
    }      
    
}